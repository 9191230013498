<template>
    <v-container>
        <h2>Not Fond</h2>
    </v-container>
</template>
<script>
export default {
  
}
</script>

<style scoped>

</style>